import React from 'react'
import { config, useSpring } from 'react-spring'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { Link } from 'gatsby'
import Layout, { MyPBox } from '../components/layout'
import { AnimatedBox, Button } from '../elements'
import SEO from '../components/SEO'
import * as cfg from '../../config'
import IconSmile from '../icon_smile2.svg'
import IconPaint from '../icon_paint.svg'
import IconCrane from '../icon_crane.svg'
import IconAt from '../icon_at.svg'

const Content = styled(AnimatedBox)<{ bg: string }>`
  background-color: ${props => transparentize(0.9, props.bg)};

  .photoCaption {
    font-size: 0.8rem;
    text-align: center;
    font-style: italic;
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.1rem;
    margin-top: 2rem;
  }
`

const PButtonEmpty = styled(Button)<{ color: string }>`
  background-color: transparent;
`

const SvgIcon = styled.img<{ size: string }>`
  width: ${props => props.size};
  height: ${props => props.size};
`

const Legal = () => {
  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  const contentAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })
  const contentAfterAnimation = useSpring({ config: config.slow, delay: 800, from: { opacity: 0 }, to: { opacity: 1 } })

  return (
    <Layout>
      <SEO title={`Impressum / Legal Notice${cfg.siteTitleSuffix}`} desc="Legal Notice" />
      <AnimatedBox style={titleAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8]}>
        <h1>Impressum / Legal Notice</h1>
      </AnimatedBox>
      <Content style={contentAnimation} bg={cfg.backgroundColor} py={8}>
        <MyPBox pb={[1]}>
          <p>
            This page is mandatory for websites from Germany and provides information about the person responsible for
            the content, relevant data protection disclaimers, etc.
          </p>
          <p>
            In case you are wondering why there is no disclaimer, data protection description and opt-out for tracking
            and analytics: There are no analytic and tracking features on this website :) I think there are enough
            bloated websites on the internet and this one should be plain and simple.
          </p>
          <p>
            Of course without those analytic features, you need to directly contact me if want to give me feedback on my
            website: Please send me an <Link to="/about">email</Link>, a{' '}
            <a href="https://twitter.com/colddevil">Tweet or a direct message on Twitter</a>! Of course you can also
            simply leave a tip via <a href="https://www.3box.io/0xfac0475b677b54f72682E0EA633Ffa1088110dcf/">ETH</a> or
            BAT to buy me a coffee :D
          </p>
          <p>
            Before those rather boring standard texts begin, here are the building blocks of this website that I really
            enjoy using and recommend:
          </p>
          <p>
            <PButtonEmpty px={4}>
              <SvgIcon src={IconCrane} size="2rem" />
            </PButtonEmpty>
            <a href="https://www.gatsbyjs.org">Gatsby Website Framework</a>
          </p>
          <p>
            <PButtonEmpty px={4}>
              <SvgIcon src={IconPaint} size="2rem" />
            </PButtonEmpty>
            <a href="https://github.com/LekoArts/gatsby-starter-portfolio-jodie">Gatsby Template "Jodie" by LekoArts</a>
          </p>
          <p>
            <PButtonEmpty px={4}>
              <SvgIcon src={IconSmile} size="2rem" />
            </PButtonEmpty>
            <a href="https://streamlineicons.com">Streamline Icons</a>
          </p>
          <p style={{ paddingTop: '1rem' }}>
            Please note that the following texts are composed to the best of my knowledge and that this page was last
            updated on 2020-03-27. In case you have any questions regarding the terms, conditions, disclaimers and the
            privacy policy document, please <Link to="/about">contact me</Link>.
          </p>
        </MyPBox>
        <MyPBox pb={0}>
          <h2>Terms and Conditions</h2>
        </MyPBox>
        <MyPBox style={contentAfterAnimation}>
          <p>
            These terms and conditions (&quot;Terms&quot;, &quot;Agreement&quot;) are an agreement between the website
            operator Florian Blum (&quot;Website Operator&quot;, &quot;us&quot;, &quot;we&quot; or &quot;our&quot;) and
            you (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;). This Agreement sets forth the general terms and
            conditions of your use of the website 'florianblum.com' and any of its products or services (collectively,
            &quot;Website&quot; or &quot;Services&quot;).
          </p>
          <h3>Links to other websites</h3>
          <p>
            Although this Website may link to other websites, we are not, directly or indirectly, implying any approval,
            association, sponsorship, endorsement, or affiliation with any linked website, unless specifically stated
            herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any
            businesses or individuals or the content of their websites. We do not assume any responsibility or liability
            for the actions, products, services, and content of any other third-parties. You should carefully review the
            legal statements and other conditions of use of any website which you access through a link from this
            Website. Your linking to any other off-site websites is at your own risk. Site owners and content may change
            without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.
          </p>
          <h3>Limitation of liability</h3>
          <p>
            To the fullest extent permitted by applicable law, in no event will the Website Operator, its affiliates,
            officers, directors, employees, agents, suppliers or licensors be liable to any person for (a): any
            indirect, incidental, special, punitive, cover or consequential damages (including, without limitation,
            damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business
            interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of
            liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or
            otherwise, even if the Website Operator has been advised as to the possibility of such damages or could have
            foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of Website
            Operator and its affiliates, officers, employees, agents, suppliers and licensors, relating to the services
            will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to Website
            Operator for the prior one month period prior to the first event or occurrence giving rise to such
            liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any
            losses or fails of its essential purpose.
          </p>
          <h3>Changes and amendments</h3>
          <p>
            We reserve the right to modify this Agreement or its policies relating to the Website or Services at any
            time, effective upon posting of an updated version of this Agreement on the Website. When we do, we will
            revise the updated date on this page. Continued use of the Website after any such changes shall constitute
            your consent to such changes.
          </p>
          <h3>Acceptance of these terms</h3>
          <p>
            You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the
            Website or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms
            of this Agreement, you are not authorized to use or access the Website and its Services.
          </p>
          <h3>Disclaimers</h3>
          <p>
            All the information on this website is published in good faith and for general information purpose only. The
            Website Operator does not make any warranties about the completeness, reliability and accuracy of this
            information. Any action you take upon the information you find on this website, is strictly at your own
            risk. The Website Operator will not be liable for any losses and/or damages in connection with the use of
            our website.
          </p>
          <p>
            Please be also aware that when you leave our website, other sites may have different privacy policies and
            terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as
            their "Terms of Service" before engaging in any business or uploading any information.
          </p>
          <h3>Sources</h3>
          <p>
            <a href="https://www.websitepolicies.com">WebsitePolicies.com</a> and{' '}
            <a href="https://www.disclaimergenerator.net ">DisclaimerGenerator.net</a>
          </p>
        </MyPBox>
        <MyPBox pb={0}>
          <h2>Privacy Policy</h2>
        </MyPBox>
        <MyPBox style={contentAfterAnimation}>
          <p>
            This Privacy Policy document contains types of information that is collected and recorded by FlorianBlum.com
            and how it is used.
          </p>

          <h3>General Data Protection Regulation (GDPR)</h3>
          <p>We are a Data Controller of your information.</p>

          <p>
            FlorianBlum.com legal basis for collecting and using the personal information described in this Privacy
            Policy depends on the Personal Information we collect and the specific context in which we collect the
            information:
          </p>
          <ul>
            <li>FlorianBlum.com needs to perform a contract with you</li>
            <li>You have given FlorianBlum.com permission to do so</li>
            <li>Processing your personal information is in FlorianBlum.com legitimate interests</li>
            <li>FlorianBlum.com needs to comply with the law</li>
          </ul>

          <p>
            FlorianBlum.com will retain your personal information only for as long as is necessary for the purposes set
            out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with
            our legal obligations, resolve disputes, and enforce our policies.
          </p>

          <p>
            If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you
            wish to be informed what Personal Information we hold about you and if you want it to be removed from our
            systems, please contact us.
          </p>

          <p>In certain circumstances, you have the following data protection rights:</p>
          <ul>
            <li>The right to access, update or to delete the information we have on you.</li>
            <li>The right of rectification.</li>
            <li>The right to object.</li>
            <li>The right of restriction.</li>
            <li>The right to data portability</li>
            <li>The right to withdraw consent</li>
          </ul>

          <h3>Log Files</h3>

          <p>
            FlorianBlum.com follows a standard procedure of using log files. These files log visitors when they visit
            websites. All hosting companies do this and a part of hosting services' analytics. The information collected
            by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date
            and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any
            information that is personally identifiable. The purpose of the information is for analyzing trends,
            administering the site, tracking users' movement on the website, and gathering demographic information.
          </p>

          <h3>Children's Information</h3>

          <p>
            Another part of our priority is adding protection for children while using the internet. We encourage
            parents and guardians to observe, participate in, and/or monitor and guide their online activity.
          </p>

          <p>
            FlorianBlum.com does not knowingly collect any Personal Identifiable Information from children under the age
            of 13. If you think that your child provided this kind of information on our website, we strongly encourage
            you to contact us immediately and we will do our best efforts to promptly remove such information from our
            records.
          </p>

          <h3>Online Privacy Policy Only</h3>

          <p>
            Our Privacy Policy applies only to our online activities and is valid for visitors to our website with
            regards to the information that they shared and/or collect in FlorianBlum.com. This policy is not applicable
            to any information collected offline or via channels other than this website.
          </p>

          <h3>Consent</h3>

          <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

          <h3>Source</h3>
          <p>
            <a href="https://www.gdprprivacypolicy.net">www.gdprprivacypolicy.net</a>
          </p>
        </MyPBox>
        <MyPBox pb={0}>
          <h2>Impressum</h2>
        </MyPBox>
        <MyPBox style={contentAfterAnimation}>
          <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
          <p>
            Florian Blum
            <br />
            D&uuml;ppelstra&szlig;e 13
            <br />
            45476 M&uuml;lheim an der Ruhr
          </p>
          <h3>Kontakt</h3>
          <p>
            Telefon: +49-201-183-7047
            <br />
            E-Mail: mail <SvgIcon src={IconAt} size="1rem" /> florianblum.com
          </p>
          <h3>Haftung f&uuml;r Inhalte</h3>
          <p>
            Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten
            nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter
            jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder
            nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen.
          </p>
          <p>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
            bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der
            Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>
          <h3>Haftung f&uuml;r Links</h3>
          <p>
            Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
            Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die
            Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
            verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e
            &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
          </p>
          <p>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
            Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
            umgehend entfernen.
          </p>
          <h3>Urheberrecht</h3>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
            Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung
            au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen
            Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht
            kommerziellen Gebrauch gestattet.
          </p>
          <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
            beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
          <h3>Quelle</h3>
          <p>
            <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
          </p>
        </MyPBox>
        <MyPBox pb={0}>
          <h2>Datenschutzerklärung</h2>
        </MyPBox>
        <MyPBox style={contentAfterAnimation}>
          <h3>Allgemeine Hinweise</h3>
          <p>
            Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen
            Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            pers&ouml;nlich identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz
            entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
          </p>
          <h3>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</h3>
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten sind im
            Impressum weiter oben zu finden.
          </p>
          <h3>Wie erfassen wir Ihre Daten?</h3>
          <p>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B.
            um Daten handeln, die Sie in ein Kontaktformular eingeben.
          </p>
          <p>
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme
            erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des
            Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
          </p>
          <h3>Wof&uuml;r nutzen wir Ihre Daten?</h3>
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten.
            Andere Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.
          </p>
          <h3>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h3>
          <p>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer
            gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder
            L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
            k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das
            Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten
            zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde
            zu.
          </p>
          <p>
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit unter der im Impressum
            angegebenen Adresse an uns wenden.
          </p>
          <h3>Externes Hosting</h3>
          <p>
            Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf
            dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um
            IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
            Webseitenzugriffe und sonstige Daten, die &uuml;ber eine Website generiert werden, handeln.
          </p>
          <p>
            Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf&uuml;llung gegen&uuml;ber unseren potenziellen
            und bestehenden Kunden (Art. 6 Abs. 1 Lit. B DSGVO) und im Interesse einer sicheren, schnellen und
            effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 Lit.
            F DSGVO).
          </p>
          <p>
            Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erf&uuml;llung seiner Leistungspflichten
            erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.
          </p>
          <h3>Datenschutz</h3>
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre
            personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
            Datenschutzerkl&auml;rung.
          </p>
          <p>
            Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
            sind Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
            Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und wof&uuml;r wir sie nutzen. Sie
            erl&auml;utert auch, wie und zu welchem Zweck das geschieht.
          </p>
          <p>
            Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per
            E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch
            Dritte ist nicht m&ouml;glich.
          </p>
          <h3>Hinweis zur verantwortlichen Stelle</h3>
          <p>
            Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist der Webseitenbetreiber.
            Name und Kontaktdaten siehe Impressum weiter oben.:
          </p>
          <p>
            Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit
            anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
            E-Mail-Adressen o. &Auml;.) entscheidet.
          </p>
          <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
          <p>
            Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie
            k&ouml;nnen eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung
            per E-Mail an uns. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
            Widerruf unber&uuml;hrt.
          </p>
          <h3>
            Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21
            DSGVO)
          </h3>
          <p>
            Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 Lit. E oder F DSGVO erfolgt, haben Sie jederzeit
            das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer
            personenbezogenen Daten widerspruch einzulegen; dies gilt auch f&uuml;r ein auf diese Bestimmungen
            gest&uuml;tztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie
            dieser Datenschutzerkl&auml;rung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen
            personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige
            Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen
            oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen
            (Widerspruch nach Art. 21 Abs. 1 DSGVO).
          </p>
          <p>
            Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht,
            jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger
            Werbung einzulegen; dies gilt auch f&uuml;r das Profiling, soweit es mit solcher Direktwerbung in Verbindung
            steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten anschliessend nicht mehr zum Zwecke der
            Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).
          </p>
          <h3>Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde</h3>
          <p>
            Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
            Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres
            Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht
            unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
          </p>
          <h3>Recht auf Daten&uuml;bertragbarkeit</h3>
          <p>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags
            automatisiert verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format
            aush&auml;ndigen zu lassen. Sofern Sie die direkte &Uuml;bertragung der Daten an einen anderen
            Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </p>
          <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>
          <p>
            Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie
            zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
            TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des
            Browsers von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile.
          </p>
          <p>
            Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns
            &uuml;bermitteln, nicht von Dritten mitgelesen werden.
          </p>
          <h3>Auskunft, L&ouml;schung und Berichtigung</h3>
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
            &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck der
            Datenverarbeitung und ggf. ein Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu sowie zu
            weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der im Impressum
            angegebenen Adresse an uns wenden.
          </p>
          <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>
          <p>
            Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            Hierzu k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht
            auf Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:
          </p>
          <ul>
            <li>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen
              wir in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das
              Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen
              Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.
            </li>
            <li>
              Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung,
              Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
              L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen
              Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
              &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
              Daten zu verlangen.
            </li>
          </ul>
          <p>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten
            &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
            nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses
            der Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.
          </p>
          <h3>Server-Log-Dateien</h3>
          <p>
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien,
            die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:
          </p>
          <ul>
            <li>Browsertyp und Browserversion</li> <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li> <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>
          </ul>
          <p>Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>
          <p>
            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. F DSGVO. Der Websitebetreiber hat
            ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website
            &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst werden.
          </p>
          <h3>Anfrage per E-Mail oder Telefon</h3>
          <p>
            Wenn Sie uns per E-Mail oder Telefon kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden
            personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und
            verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. B DSGVO, sofern Ihre Anfrage mit
            der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher
            Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
            berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 Lit. F
            DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 Lit. A DSGVO) sofern diese abgefragt wurde.
          </p>
          <p>
            Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie uns zur
            L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die
            Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
            gesetzliche Bestimmungen &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
            unber&uuml;hrt.
          </p>
          <h3>Twitter Plugin</h3>
          <p>
            Auf dieser Website sind Funktionen des Dienstes Twitter eingebunden. Diese Funktionen werden angeboten durch
            die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Durch das Benutzen von
            Twitter und der Funktion &bdquo;Re-Tweet&ldquo; werden die von Ihnen besuchten Websites mit Ihrem
            Twitter-Account verkn&uuml;pft und anderen Nutzern bekannt gegeben. Dabei werden auch Daten an Twitter
            &uuml;bertragen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
            &uuml;bermittelten Daten sowie deren Nutzung durch Twitter erhalten. Weitere Informationen hierzu finden Sie
            in der Datenschutzerkl&auml;rung von Twitter unter folgender Adresse:{' '}
            <a href="https://twitter.com/de/privacy" rel="noopener noreferrer">
              https://twitter.com/de/privacy
            </a>
          </p>
          <p>
            Die Verwendung des Twitter-Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 Lit. F DSGVO. Der
            Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst umfangreichen Sichtbarkeit in den
            Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 Lit. A DSGVO; die Einwilligung ist jederzeit
            widerrufbar.
          </p>
          <p>
            Ihre Datenschutzeinstellungen bei Twitter k&ouml;nnen Sie in den Konto-Einstellungen unter{' '}
            <a href="https://twitter.com/account/settings" rel="noopener noreferrer">
              https://twitter.com/account/settings
            </a>{' '}
            &auml;ndern.
          </p>
          <h3>Quelle</h3>
          <p>
            <a href="https://www.e-recht24.de/muster-datenschutzerklaerung.html">
              https://www.e-recht24.de/muster-datenschutzerklaerung.html
            </a>
          </p>
        </MyPBox>
      </Content>
    </Layout>
  )
}

export default Legal
